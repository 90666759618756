<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
<!--      <v-btn text to="/kpi" >Portfolio Manager</v-btn>-->
      <v-btn icon to="/kpi" aria-label="alle Eingaben löschen">
        <v-icon >mdi-home-city</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn icon @click="$store.dispatch('clear')" aria-label="alle Eingaben löschen">
        <v-icon >mdi-eraser</v-icon>
      </v-btn>

      <span>
        <v-fab-transition>
          <v-btn icon
           @click="btn_settings_clicked"
           :key="this.$route.path === '/settings'"  aria-label="Einstellungen">
            <v-icon v-if="this.$route.path === '/settings'">mdi-close</v-icon>
            <v-icon v-else>mdi-cog</v-icon>
          </v-btn>
        </v-fab-transition>
      </span>
    </v-app-bar>


    <v-main>
      <PWAPrompt/>
      <v-scroll-x-transition :hide-on-leave="true">
        <router-view/>
      </v-scroll-x-transition>
    </v-main>
  </v-app>


</template>

<script>
import PWAPrompt from "@/components/PWAPrompt";
export default {
  name: 'App',
  components: {PWAPrompt},
  data: () => ({
    previous_path: '/kpi',
  }),
  methods: {
    btn_settings_clicked: function(){
      var path = window.location.pathname;
      console.log(path)
      if(path !== '/settings'){
        this.previous_path = path;
        this.$router.push('/settings');
      }else{
        this.$router.push(this.previous_path);
      }
    }
  }
};
</script>
<style>
.v-app-bar .theme--dark.v-btn--active::before{
  opacity: 0;
}
</style>