import Vue from 'vue'
import VueRouter from 'vue-router'
import KPI from '../views/Calc-Kpi.vue'
import kpiStart from '../views/KpiStart.vue'

Vue.use(VueRouter)

  const routes = [
  { path: '/', redirect: '/kpi/' },
  {
    path: '/kpi/',
    component: KPI,
    children: [
      {
        path: '',
        component: kpiStart
      },
      {
        path: 'detail',
        name: 'Details',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "kpi" */ '../views/Calc-Detail.vue')
      },
    ]
  },
  {
    path: '/settings',
    name: 'Settings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "settings" */ '../views/Calc-Settings.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
