<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="6" class="pr-3">
        <v-text-field v-model.number="sqaremeter" label="Wohnfläche" suffix="m²" inputmode="numeric" pattern="[0-9.]*"/>
      </v-col>
      <v-col cols="6" class="pl-3">
        <v-text-field v-model.number="plz" label="PLZ" inputmode="numeric" pattern="[0-9]{5}">
          <template v-slot:append>
            <a :href="'https://www.google.de/maps/place/'+plz" target="_blank" rel="noopener noreferrer" tabindex="-1"
               aria-label="google maps">
              <v-icon>mdi-google-maps</v-icon>
            </a>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="6" class="pr-3">
        <v-text-field v-model.number="price" label="Kaufpreis" suffix="€" inputmode="numeric"
                      pattern="[0-9.]*"/>
      </v-col>
      <v-col cols="6" class="pl-3">
        <v-text-field
            v-model.number="additional_costs"
            label="Nebenkosten"
            suffix="€"
            inputmode="numeric"
            pattern="[0-9.]*"
        >
          <template v-slot:append-outer>
            <v-icon @click="additional_costs_calc=!additional_costs_calc" aria-label="Nebenkosten berechnen">
              mdi-calculator-variant-outline
            </v-icon>
            <v-icon :color="wizzard_color_additional_costs" @click="estimate_additional_costs"
                    aria-label="estimate additional costs">mdi-auto-fix
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="additional_costs_calc">
      <v-col>
        <v-card class="mb-6">
          <v-card-title>Nebenkostenberechnung:</v-card-title>
          <v-card-text>
            <v-text-field
                v-model.number="additional_costs_brokerage_fees"
                label="Maklergebühr"
                suffix="€"
                inputmode="numeric"
                pattern="[0-9.]*"
            >
            </v-text-field>

            <v-text-field
                v-model.number="additional_costs_grunderwerbssteuer"
                label="Grunderwerbssteuer"
                suffix="€"
                inputmode="numeric"
                pattern="[0-9.]*"
            >
            </v-text-field>

            <v-text-field
                v-model.number="additional_costs_notar"
                label="Notar"
                suffix="€"
                inputmode="numeric"
                pattern="[0-9.]*"
            >
            </v-text-field>

            <v-text-field
                v-model.number="additional_costs_grundbucheintrag"
                label="Grundbucheintrag"
                suffix="€"
                inputmode="numeric"
                pattern="[0-9.]*"
            >
            </v-text-field>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="6" class="pr-3">
        <v-text-field
            v-model.number="net_rent"
            label="Kaltmiete"
            suffix="€"
            inputmode="numeric"
            pattern="[0-9.]*"
        >
          <template v-slot:append-outer>
            <v-icon :color="wizzard_color_net_rent" @click="estimate_net_rent" aria-label="estimate net rent">
              mdi-auto-fix
            </v-icon>
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="6" class="pl-3">
        <v-text-field
            v-model.number="haus_geld"
            label="Hausgeld"
            suffix="€"
            inputmode="numeric"
            pattern="[0-9.]*"
        >
          <template v-slot:append-outer>
            <v-icon @click="haus_geld_calc=!haus_geld_calc" aria-label="Hausgeld berechnen">
              mdi-calculator-variant-outline
            </v-icon>
            <v-icon :color="wizzard_color_haus_geld" @click="estimate_haus_geld" aria-label="Hausgeld schätzen">
              mdi-auto-fix
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="haus_geld_calc">
      <v-col>
        <v-card class="mb-6">
          <v-card-title>Hausgeldberechnung::</v-card-title>
          <v-card-text>
            <v-text-field
                v-model.number="haus_geld_umlagefaehig"
                label="umlagefähig"
                suffix="€"
                inputmode="numeric"
                pattern="[0-9.]*"
            >
              <!--
                                      <template v-slot:append-outer>
                                          <v-icon :color="wizzard_color_haus_geld" @click="estimate_haus_geld">mdi-pencil</v-icon>
                                      </template>
              -->
            </v-text-field>

            <v-text-field
                v-model.number="haus_geld_nicht_umlagefaehig"
                label="nicht umlagefähig"
                suffix="€"
                inputmode="numeric"
                pattern="[0-9.]*"
            >
              <!--
                                      <template v-slot:append-outer>
                                          <v-icon :color="wizzard_color_haus_geld" @click="estimate_haus_geld">mdi-pencil</v-icon>
                                      </template>
              -->
            </v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-container class="pa-0">
      <v-btn v-if="pa_factor===1" small outlined @click="pa_factor=12">jährlich</v-btn>
      <v-btn v-else small outlined @click="pa_factor=1">monatlich</v-btn>

      <v-row>
        <v-col cols="12" sm="6">
          <v-card v-bind:color="status_color">
            <v-card-title>Basisdaten</v-card-title>
            <v-card-text style="color: black">
              Gesamtpreis: {{ total_price | asNumber }} € <br>
              Bruttomietrendite: {{ gross_rental_return | asNumber }} % <br>
              Quadratmeterpreis: {{ quadratmeterpreis | asNumber }} €<br>
              Netto-Miete / m²: {{ netrent_per_squaremeter | asNumber }} €
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card v-bind:color="status_color">
            <v-card-title>Finanzauswertung</v-card-title>
            <v-card-text style="color: black">
              Cashflow operativ: {{ cacheflow1 | asNumber }} €<br>
              Cashflow nach Steuern: {{ cacheflow2 | asNumber }} €<br><br>
              Vermögenswachstum: {{ vermoegens_wachstum | asNumber }} € <br>
              Eigenkapitalrendite: {{ ek_rendite | asNumber }} % <br>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-slider
              v-model="ek_percent"
              min="0"
              max="100"
              label="Eigenkaptial-Regler"
          ></v-slider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-slider
              v-model="tilgung_percent"
              min="1"
              max="10"
              thumb-label="always"
              label="Tilgungs-Regler in %"
          ></v-slider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <v-card v-bind:color="status_color">
            <v-card-title>Finanzierung</v-card-title>
            <v-card-text style="color: black">
              Eigenkapital: {{ eigen_kaptial | asNumber }} € <br>
              Fremdkapital: {{ fremd_kapital | asNumber }} € <br>
              EK-Anteil: {{ eigen_kaptial / fremd_kapital * 100 | asNumber }} % <br>
              <br>
              Zinz: {{ zins_pa * pa_factor / 12 | asNumber }} € <br>
              Tilgung: {{ tilgung_pa * pa_factor / 12 | asNumber }} €<br>
              Annuität:  {{ annuity | asNumber }} €
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </v-container>
  </v-container>
</template>
<script>

export default {
  data: function () {
    return {
      haus_geld_calc: false,
      additional_costs_calc: false,
      pa_factor: 1,
      tilgung_percent: 3,
    }
  },
  computed: {
    sqaremeter: {
      get() {
        return this.$store.state.sqaremeter
      },
      set(value) {
        this.$store.commit('sqaremeter', value)
      }
    },
    plz: {
      get() {
        return this.$store.state.plz
      },
      set(value) {
        this.$store.commit('plz', value)
      }
    },

    price: {
      get() {
        return this.$store.state.price
      },
      set(value) {
        this.$store.commit('price', value)
      }
    },
    additional_costs: {
      get() {
        return this.$store.state.additional_costs_brokerage_fees
            + this.$store.state.additional_costs_grunderwerbssteuer
            + this.$store.state.additional_costs_notar
            + this.$store.state.additional_costs_grundbucheintrag
      },
      set(value) {
        console.log("writing additional_costs");
        this.$store.commit('additional_costs_brokerage_fees', value / 4);
        this.$store.commit('additional_costs_grunderwerbssteuer', value / 4);
        this.$store.commit('additional_costs_notar', value / 4);
        this.$store.commit('additional_costs_grundbucheintrag', value / 4);
      }
    },
    additional_costs_brokerage_fees: {
      get() {
        return this.$store.state.additional_costs_brokerage_fees
      },
      set(value) {
        if (!value || value < 0) {
          value = 0;
        }
        this.$store.commit('additional_costs_brokerage_fees', value)
      }
    },
    additional_costs_grunderwerbssteuer: {
      get() {
        return this.$store.state.additional_costs_grunderwerbssteuer
      },
      set(value) {
        if (!value || value < 0) {
          value = 0;
        }
        this.$store.commit('additional_costs_grunderwerbssteuer', value)
      }
    },
    additional_costs_notar: {
      get() {
        return this.$store.state.additional_costs_notar
      },
      set(value) {
        if (!value || value < 0) {
          value = 0;
        }
        this.$store.commit('additional_costs_notar', value)
      }
    },
    additional_costs_grundbucheintrag: {
      get() {
        return this.$store.state.additional_costs_grundbucheintrag
      },
      set(value) {
        if (!value || value < 0) {
          value = 0;
        }
        this.$store.commit('additional_costs_grundbucheintrag', value)
      }
    },
    net_rent: {
      get() {
        if (this.$store.state.net_rent === '') {
          return '';
        } else {
          return this.$store.state.net_rent * this.pa_factor
        }
      },
      set(value) {
        this.$store.commit('net_rent', value / this.pa_factor)
      }
    },
    haus_geld: {
      get() {
        if (!this.haus_geld_umlagefaehig && !this.haus_geld_nicht_umlagefaehig) {
          return '';
        } else {
          return (this.haus_geld_umlagefaehig + this.haus_geld_nicht_umlagefaehig);
        }
      },
      set(value) {
        var umlage = evenRound(value / this.pa_factor * 3 / 4);
        this.$store.commit('haus_geld_umlagefaehig', umlage, 2);
        this.$store.commit('haus_geld_nicht_umlagefaehig', (value / this.pa_factor) - umlage);
      }
    },
    haus_geld_umlagefaehig: {
      get() {
        return this.$store.state.haus_geld_umlagefaehig * this.pa_factor
      },
      set(value) {
        this.$store.commit('haus_geld_umlagefaehig', value / this.pa_factor)
      }
    },
    haus_geld_nicht_umlagefaehig: {
      get() {
        return this.$store.state.haus_geld_nicht_umlagefaehig * this.pa_factor
      },
      set(value) {
        this.$store.commit('haus_geld_nicht_umlagefaehig', value / this.pa_factor)
      }
    },
    ek_percent: {
      get() {
        return this.$store.state.ek_percent
      },
      set(value) {
        this.$store.commit('ek_percent', value)
      }
    },


    total_price: function () {
      return this.price + this.additional_costs;
    },
    gross_rental_return: function () {
      return evenRound(this.net_rent * 12 / this.pa_factor / this.price * 100, 3);
    },
    quadratmeterpreis: function () {
      return evenRound(this.total_price / this.sqaremeter, 2);
    },
    eigen_kaptial: function () {
      var ek = Math.min((this.$store.state.max_eigen_kaptial - this.additional_costs), this.total_price) * this.ek_percent / 100 + this.additional_costs;
      return evenRound(ek, 2);
    },
    fremd_kapital: function () {
      return evenRound(Math.max(this.total_price - this.eigen_kaptial, 0), 2);
    },
    zins_pa: function () {
      return evenRound(this.fremd_kapital * this.$store.state.intrest_rate / 100, 2);
    },
    tilgung_pa: function () {
      return evenRound(this.fremd_kapital * this.tilgung_percent / 100, 2);
    },
    annuity: function () {
      return evenRound((this.zins_pa + this.tilgung_pa) * this.pa_factor / 12, 2);
    },
    //TODO rücklagen sanierung
    //TODO rücklagen mietausfall
    ruecklage_mietausfall: function () {
      return evenRound(this.net_rent * 0.03, 2);
    },
    cacheflow1: function () {
      return evenRound(this.net_rent - this.annuity - this.haus_geld_nicht_umlagefaehig, 2);
    },
    afa: function () {
      return this.total_price * 0.6 * 0.02 * this.pa_factor / 12;
    },
    tax: function () {
      return evenRound((this.net_rent - this.haus_geld_nicht_umlagefaehig - this.zins_pa * this.pa_factor / 12 - this.afa) * this.$store.state.grenz_steuer_satz / 100, 2);
    },
    cacheflow2: function () {
      return this.cacheflow1 - this.tax;
    },

    vermoegens_wachstum: function () {
      return evenRound(this.tilgung_pa * this.pa_factor / 12 + this.cacheflow2, 2);
    },
    ek_rendite: function () {
      return evenRound(this.vermoegens_wachstum * 12 / this.pa_factor / this.eigen_kaptial * 100, 3);
    },
    netrent_per_squaremeter: function () {
      return evenRound(this.net_rent / this.sqaremeter, 2);
    },


    wizzard_color_additional_costs: function () {
      return this.additional_costs === this.calculate_additional_costs() ? "blue" : "";
    },
    wizzard_color_net_rent: function () {
      return this.net_rent === this.calculate_net_rent() ? "blue" : "";
    },
    wizzard_color_haus_geld: function () {
      return this.haus_geld === this.calculate_haus_geld() ? "blue" : "";
    },

    status_color: function () {
      if (this.cacheflow1 > 0) {
        return 'green';
      } else if (this.cacheflow1 > -50) {
        return 'yellow';
      } else {
        return 'red';
      }
    },
  },
  methods: {
    estimate_additional_costs() {
      this.$store.commit('additional_costs_brokerage_fees', this.calculate_additional_costs_brokerage_fees())
      this.$store.commit('additional_costs_grunderwerbssteuer', this.calculate_additional_costs_grunderwerbssteuer())
      this.$store.commit('additional_costs_notar', this.calculate_additional_costs_notar())
      this.$store.commit('additional_costs_grundbucheintrag', this.calculate_additional_costs_grundbucheintrag())
      this.$store.commit('additional_costs', this.calculate_additional_costs())
    },
    calculate_additional_costs() {
      return this.calculate_additional_costs_brokerage_fees()
          + this.calculate_additional_costs_grunderwerbssteuer()
          + this.calculate_additional_costs_notar()
          + this.calculate_additional_costs_grundbucheintrag()
    },
    calculate_additional_costs_brokerage_fees() {
      return evenRound(this.price * this.$store.state.default_brokerage_fees / 100, 2)
    },
    calculate_additional_costs_grunderwerbssteuer() {
      return evenRound(this.price * this.$store.state.default_grunderwerbssteuer / 100, 2)
    },
    calculate_additional_costs_notar() {
      return evenRound(this.price * this.$store.state.default_notarkosten / 100, 2)
    },
    calculate_additional_costs_grundbucheintrag() {
      return evenRound(this.price * this.$store.state.default_grundbucheintrag / 100, 2)
    },


    estimate_net_rent() {
      this.$store.commit('net_rent', this.calculate_net_rent())
    },
    calculate_net_rent() {
      //TODO
      return evenRound(this.sqaremeter * this.$store.state.default_square_meter_rental_price, 0)
    },

    estimate_haus_geld() {
      this.$store.commit('haus_geld_nicht_umlagefaehig', this.calculate_haus_geld_nicht_umlagefaehig())
      this.$store.commit('haus_geld_umlagefaehig', this.calculate_haus_geld_umlagefaehig())
    },
    calculate_haus_geld_nicht_umlagefaehig() {
      return evenRound(this.sqaremeter * 0.7 + 20, 0);
    },
    calculate_haus_geld_umlagefaehig() {
      return evenRound(this.sqaremeter * 2, 0);
    },
    calculate_haus_geld() {
      return this.calculate_haus_geld_umlagefaehig() + this.calculate_haus_geld_nicht_umlagefaehig();
    }

  },
  filters: {
    asNumber(value) {
      if (isNaN(value)) {
        return '-'
      } else {
        return new Intl.NumberFormat('de-DE').format(evenRound(value, 2));
      }
    }
  }

}


function evenRound(num, decimalPlaces) {
  var d = decimalPlaces || 0;
  var m = Math.pow(10, d);
  var n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  var i = Math.floor(n), f = n - i;
  var e = 1e-8; // Allow for rounding errors in f
  var r = (f > 0.5 - e && f < 0.5 + e) ?
      ((i % 2 == 0) ? i : i + 1) : Math.round(n);
  return d ? r / m : r;
}
</script>