<template>
  <v-banner v-if="shown"
            transition="slide-y-transition"
  >
    <v-icon
        slot="icon"
        color="info"
        size="36"
    >
      mdi-cellphone-arrow-down-variant
    </v-icon>
    Möchtest du die App auf dem Home screen installieren?

    <template v-slot:actions>
      <v-btn
          color="primary"
          text
          @click="installPWA"
      >
        ja
      </v-btn>
      <v-btn
          color="secondary"
          text
          @click="dismissPrompt"
      >
        nein
      </v-btn>
    </template>
  </v-banner>

</template>

<script>
export default {
  data: () => ({
    shown: false,
  }),

  beforeMount() {
    console.log("pwa-promt: beforeMount")
    window.addEventListener('beforeinstallprompt', (e) => {
      console.log("pwa-promt: beforeinstallprompt-event")
      e.preventDefault()
      this.installEvent = e
      this.shown = true
    })
  },

  methods: {
    dismissPrompt() {
      console.log("pwa-promt: dismissPrompt()")
      this.shown = false
    },

    installPWA() {
      console.log("pwa-promt: installPWA()")
      this.installEvent.prompt()
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt() // Hide the prompt once the user's clicked
        if (choice.outcome === 'accepted') {
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      })
    },
  }
}
</script>