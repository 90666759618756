import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//#28325;50;99000;12672;4200;219
var arr = [24539, 92, 119000, 14256, 6240, 160];
if(window.location.hash !== ""){
  arr = window.location.hash.slice(1).split(';');
}

function str2num(str,fallback=0){
  if(!isNaN(str) && str !== ""){
    return parseInt(str);
  }else{
    return fallback;
  }
}
const init_square_meter_rental_price = 8.5;

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    //Settings
    count: 0,
    grenz_steuer_satz: 38,
    intrest_rate: 2.5,
    max_eigen_kaptial: 50000,
    default_square_meter_rental_price: init_square_meter_rental_price,
    default_brokerage_fees: 3.57,
    default_grunderwerbssteuer: 6.5,
    default_notarkosten: 1.5,
    default_grundbucheintrag: 0.5,


    //Basic-KPIs
    sqaremeter: str2num(arr[1]),
    plz: arr[0],
    price: str2num(arr[2]),
    additional_costs: str2num(arr[3]),
    additional_costs_brokerage_fees: 100,
    additional_costs_grunderwerbssteuer: 100,
    additional_costs_notar: 100,
    additional_costs_grundbucheintrag: 100,
    net_rent: str2num(arr[4], str2num(arr[1], 1)*init_square_meter_rental_price*12)/12,
    haus_geld_umlagefaehig: str2num(arr[5]) * 3/4,
    haus_geld_nicht_umlagefaehig: str2num(arr[5]) *1/4,
    ek_percent: 100,
  },
  mutations: {
    increment (state) {
      state.count++
    },
    set_tax (state, value) {
      state.grenz_steuer_satz = value;
    },
    set_intrest_rate (state, value) {
      state.intrest_rate = value;
    },
    max_eigen_kaptial (state, value) {
      state.max_eigen_kaptial = value;
    },
    default_brokerage_fees (state, value) {
      state.default_brokerage_fees = value;
    },
    default_grunderwerbssteuer (state, value) {
      state.default_grunderwerbssteuer = value;
    },
    default_notarkosten (state, value) {
      state.default_notarkosten = value;
    },
    default_grundbucheintrag (state, value) {
      state.default_grundbucheintrag = value;
    },
    default_square_meter_rental_price (state, value){
      state.default_square_meter_rental_price = value;

    },

    sqaremeter (state, value) {
      state.sqaremeter = value;
    },
    plz (state, value) {
      state.plz = value;
    },
    price (state, value) {
      state.price = value;
    },
    additional_costs (state, value) {
      state.additional_costs = value;
    },
    additional_costs_brokerage_fees (state, value) {
      state.additional_costs_brokerage_fees = value;
    },
    additional_costs_grunderwerbssteuer (state, value) {
      state.additional_costs_grunderwerbssteuer = value;
    },
    additional_costs_notar (state, value) {
      state.additional_costs_notar = value;
    },
    additional_costs_grundbucheintrag (state, value) {
      state.additional_costs_grundbucheintrag = value;
    },

    net_rent (state, value) {
      state.net_rent = value;
    },
    haus_geld_umlagefaehig (state, value) {
      state.haus_geld_umlagefaehig = value;
    },
    haus_geld_nicht_umlagefaehig (state, value) {
      state.haus_geld_nicht_umlagefaehig = value;
    },



    ek_percent (state, value) {
      state.ek_percent = value;
    },



  },
  actions: {
    clear (context) {
      context.commit('sqaremeter', '');
      context.commit('plz', '');
      context.commit('price', '');
      context.commit('additional_costs', '');
      context.commit('net_rent', '');
      context.commit('haus_geld_umlagefaehig', '');
      context.commit('haus_geld_nicht_umlagefaehig', '');

      context.commit('additional_costs_brokerage_fees', '');
      context.commit('additional_costs_grunderwerbssteuer', '');
      context.commit('additional_costs_notar', '');
      context.commit('additional_costs_grundbucheintrag', '');


      context.commit('net_rent', '');
      context.commit('haus_geld_umlagefaehig', '');
      context.commit('haus_geld_nicht_umlagefaehig', '');
    }
  },
  modules: {
  }
})
